import React from "react";
import TypeUtils from "../../../utils/TypeUtils";
import PillList from "../../common/PillList";
import "./SkillMatchBlock.scss";

export default function SkillMatchBlock(props) {

  const {skills, matchFunction, t} = props;
  const hasSkills = TypeUtils.ensureArray(skills).length > 0;

  // RENDER

  return (
    <div className="SkillMatchBlock spaced-children">
      {!hasSkills &&
      <span className="no-skills-label">{t("jobmanagement:job_no_skill_label")}</span>
      }
      {hasSkills &&
      <PillList items={skills} variant="skill" altVariant="skill-match"
                altTest={(item) => matchFunction(item)}/>
      }
    </div>
  );
}
