import React from "react";
import PrivatePage from "../page/PrivatePage";
import AddCandidateBlock from "./AddCandidateBlock";
import {withTranslation} from "react-i18next";

class CandidateAddPage extends React.Component {

  render() {
    const t = this.props.t;

    return (
      <PrivatePage>
        <h1>{t("jobmanagement:candidate_add_title")}</h1>
        <AddCandidateBlock/>
      </PrivatePage>
    );
  }
}

export default withTranslation()(CandidateAddPage);
