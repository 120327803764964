import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {withTranslation} from "react-i18next";
import ResumeDropbox from "./ResumeDropbox";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import LoadingDataError from "../common/LoadingDataError";
import QueryJobFairs from "./QueryJobFairs";
import Form from "react-bootstrap/Form";
import AddResumeTextAreaForm from "./AddResumeTextAreaForm";

class AddCandidateBlock extends React.Component {

  state = {
    fairId: "",
  };

  showLoading = () => {
    return <LoadingSpinner/>;
  };

  showError = () => {
    return <LoadingDataError/>;
  };

  onFairIdChange = event => {
    this.setState({ fairId: event.target.value });
  };


  render() {
    const t = this.props.t;

    return (
      <QueryJobFairs
        onLoading={this.showLoading}
        onError={this.showError}
        onLoaded={jobFairs =>

          <div className="AddCandidateBlock spaced-children">
            <Row>
              <Col>
                <Form.Control as="select" value={this.state.fairId} onChange={this.onFairIdChange}>
                  <option key="default" value="" disabled>{t("jobmanagement:add_resume_fair_placeholder")}</option>
                  {jobFairs.map(jobFair =>
                    <option key={jobFair._id} value={jobFair._id}>{jobFair.title} ({jobFair.city})</option>)
                  }
                </Form.Control>
              </Col>
            </Row>
            <Row className="drop-row">
              <Col>
                <ResumeDropbox fairId={this.state.fairId}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr/>
              </Col>
              <Col xs="auto">
                {t("jobmanagement:add_job_tab_or")}
              </Col>
              <Col>
                <hr/>
              </Col>
            </Row>
            <Row>
              <Col>
                <AddResumeTextAreaForm fairId={this.state.fairId}/>
              </Col>
            </Row>
          </div>
        }
      />
    );
  }
}

export default withTranslation()(AddCandidateBlock);
