import React from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import "./SignInPage.scss";
import Page from "../page/Page";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//import logo_portal from "../../img/bsl-cropped.png";
//import Image from "react-bootstrap/Image";
import SignInForm from "./SignInForm";
import {withTranslation} from "react-i18next";

class SignInPage extends React.Component {
  render() {
    return (
      <Page>
        <div className="SignInPage">
          {/*
          <Row>
            <Col>{empty row for even spacing}</Col>
          </Row>
          <Row>
            <Col>
              <Image src={logo_portal} className="portal_logo" />
            </Col>
          </Row>
          */}
          <Row className="big-space-before">
            <Col xs="12" sm="6">
              <WithTranslationSignInBlock />
            </Col>
          </Row>
        </div>
      </Page>
    );
  }
}

class SignInBlock extends React.Component {
  render() {
    const t = this.props.t;

    return (
      <Card className="SignInBlock">
        <Card.Body className="spaced-children">
          <Row>
            <Col>
              <h4>{t("session:sign_in_title")}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <SignInForm />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="ForgotPasswordLink">
                <Link to={ROUTES.PASSWORD_FORGET}>{t("session:sign_in_forgot_password_link")}</Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                {t("session:sign_in_no_account_msg")}{" "}<Link to={ROUTES.SIGN_UP}>{t("session:sign_in_sign_up_link")}</Link>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

const WithTranslationSignInBlock = withTranslation()(SignInBlock);

export default SignInPage;
