import React from "react";
import Page from "../page/Page";

class TermsPage extends React.Component {
  static termsText = (
    <div>
      <p>
        Bienvenue chez Mouse At Work! La souris au travail!
      </p>
      <p>
        Le respect de votre vie privée est primordial pour nous.
      </p>
      <p>
        Les informations que nous collectons et analysons ne seront accessibles que par les entreprises qui participent
        aux salons d'emploi.
        Seuls les recruteurs pourront utiliser ces informations pour vous contacter.
      </p>
      <p>
        Votre CV ainsi que toute autre information personnelle seront complètement supprimés de nos bases de données
        trois mois après le dernier événement.
      </p>
      <p>
        Le Groupe Mouse At Work n'intervient dans aucune procédure de recrutement et ne garantit aux chercheurs d'emploi
        aucune entrevue ni embauche dans une entreprise.
      </p>
      <p>
        Pour plus d'informations, visitez notre site web <a href="https://mouseatwork.com">mouseatwork.com</a> ou
        contactez-nous:
      </p>
      <ul>
        <li>Par courriel: <a href="mailto:info@mouseatwork.com" target="_top">info@mouseatwork.com</a></li>
        <li>Par la poste: 6207 rue de Villebois, Saint-Léonard (Québec), H1S&nbsp;1P7, Canada</li>
      </ul>
    </div>
  );

  render() {
    return (
      <Page>
        <h1>Mentions légales</h1>
        {TermsPage.termsText}
      </Page>
    );
  }
}

export default TermsPage;
