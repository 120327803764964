import React from "react";
import Dropzone from 'react-dropzone'
import "./ResumeDropbox.scss";
import {withTranslation} from "react-i18next";
import ErrorModal from "../common/ErrorModal";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import {withRouter} from "react-router-dom";
import SuccessModal from "../common/SuccessModal";
import FileConverterSupportedFormats from "../../utils/FileConverterSupportedFormats";
import xbytes from "xbytes";
import QueryMaxUploadFileSize from "../common/QueryMaxUploadFileSize";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import LoadingDataError from "../common/LoadingDataError";
import * as ROUTES from "../../constants/routes";

class ResumeDropbox extends React.Component {

  static ADD_RESUME_FROM_FILE = gql`
    mutation bslJobFairAnalyzeAndSaveFileResume($fairId: String!, $file: Upload!) {
        bslJobFairAnalyzeAndSaveFileResume(fairId: $fairId, file: $file)
    }
  `;

  state = {
    loading: false,
    showSuccess: false,
    error: null,
  };

  hideSuccess = () => {
    this.setState({ showSuccess: false }, () => {
      this.props.history.push(ROUTES.CANDIDATE_ALL);
    });
  };

  hideError = () => {
    this.setState({
      error: null,
      loading: false
    });
  };

  saveResume = (file, mutationCallback) => {
    return mutationCallback({
      variables: {
        fairId: this.props.fairId,
        file: file,
      }
    });
  };

  // Make checks on file return a promise so that the same catch clause can be used with saveResume
  fileCheckAsPromise = (files, maxUploadFileSize) => {
    const t = this.props.t;

    // Display error if more than one file is dragged
    if (files.length > 1) {
      return Promise.reject(Error(t("jobmanagement:add_resume_multiple_files_error")));
    }
    // Display error if file format is not supported
    if (!FileConverterSupportedFormats.isFormatSupported(files[0].name)) {
      return Promise.reject(Error(t("jobmanagement:add_resume_unsupported_format_error")));
    }
    // Display error if file size is too big
    if (files[0].size > maxUploadFileSize)
      return Promise.reject(Error(t("jobmanagement:add_resume_file_size_error")));

    return Promise.resolve(null);
  };

  onDrop = (files, mutationCallback, maxUploadFileSize) => {
    const t = this.props.t;

    // If we are processing another file or we haven't selected a job fair, do nothing
    if (this.state.loading || !this.props.fairId)
      return;

    // Hide previous errors, then perform save
    this.setState({ error: null, loading: true }, () => {

      // Return nothing if, for some reason, there are no files
      if (!files || files.length === 0 || files[0] === null)
        return;

      this.fileCheckAsPromise(files, maxUploadFileSize)
        .then(() => this.saveResume(files[0], mutationCallback))
        .then((result) => {
          const newResume = result.data["bslJobFairAnalyzeAndSaveFileResume"];
          this.setState({
            showSuccess: true,
          });
        })
        .catch((error) => {
          let errorMsg = t("jobmanagement:add_resume_cannot_analyze");
          if (error.message) {
            errorMsg = errorMsg + " " + t("jobmanagement:server_error", {serverError: error.message});
          }
          this.setState({error: errorMsg});
        });
    });
  };

  showLoading = () => {
    return <LoadingSpinner />;
  };

  showError = () => {
    return <LoadingDataError/>;
  };

  render() {

    const t = this.props.t;
    const refetchQueries = [];

    return (
      <QueryMaxUploadFileSize onLoading={this.showLoading} onError={this.showError} onLoaded={(maxUploadFileSize) => (
         <Mutation mutation={ResumeDropbox.ADD_RESUME_FROM_FILE} refetchQueries={refetchQueries}>
          {(uploadCallback, {loading}) => (
            <div className={"ResumeDropbox dropzone " + (loading ? "disabled" : "")}>
              {/* The modal is visible only when upload has been successful */}
              <SuccessModal show={this.state.showSuccess} handleClose={this.hideSuccess}>
                <p>{t("jobmanagement:add_resume_success_msg")}</p>
              </SuccessModal>
              {/* The modal is visible only when there are errors before or during upload */}
              <ErrorModal show={this.state.error !== null} handleClose={this.hideError}>
                {this.state.error}
              </ErrorModal>
              <Dropzone onDrop={(files) => this.onDrop(files, uploadCallback, maxUploadFileSize)}>
                {({getRootProps, getInputProps}) => (
                  <section className="container">
                    <div {...getRootProps()}>
                      {this.state.loading && <p>{t("jobmanagement:add_resume_processing")}</p>}
                      {!this.state.loading &&
                        <React.Fragment>
                          <input {...getInputProps()} />
                          <p>{t("jobmanagement:add_resume_dropbox_placeholder_1")}</p>
                          <p>{t("jobmanagement:add_resume_dropbox_placeholder_2")}</p>
                          <p>{t("jobmanagement:add_resume_dropbox_placeholder_3", {"max": xbytes(maxUploadFileSize, {space: false})})}</p>
                        </React.Fragment>
                      }
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          )}
        </Mutation>
      )}/>
    );
  }
}

export default withTranslation()(withRouter(ResumeDropbox));
