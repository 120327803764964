import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import JobOpeningsList from "./JobOpeningsList";
import QueryCurrentJobsForCompany from "./QueryCurrentJobsForCompany";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "./JobManagementPage.scss";
import {Link, Redirect, withRouter} from "react-router-dom";
import PreviewTab from "./PreviewTab";
import CandidateIcon from "@material-ui/icons/PeopleAlt";
import PreviewIcon from "@material-ui/icons/Visibility";
import StarsTab from "./StarsTab";
import FeedTab from "./FeedTab";
import PrivatePage from "../page/PrivatePage";
import TypeUtils from "../../utils/TypeUtils";
import AddJobTab from "./AddJobTab";
import LoadingDataError from "../common/LoadingDataError";
import {Trans, withTranslation} from "react-i18next";
import JobManagementRoutes from "./JobManagementRoutes";
import withAuthUser from "../session/withAuthUser";

class JobManagementPage extends React.Component {

  state = {
    selectedOpening: JobManagementRoutes.getJobIdFromRoute(this.props.match),
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Update current job ID if it has changed in url
    if (JobManagementRoutes.getJobIdFromRoute(prevProps.match) !== JobManagementRoutes.getJobIdFromRoute(this.props.match))
      this.setState({selectedOpening: JobManagementRoutes.getJobIdFromRoute(this.props.match)});
  }


  selectOpening = (opening) => {
    // Change url so that a refresh does not lose the current job opening
    this.props.history.push(JobManagementRoutes.getPreviewRoute(this.props.match, opening));
  };

  updateOpening = (opening) => {
    this.setState({selectedOpening: opening});
  };

  showLoading = () => {
    return <LoadingSpinner />;
  };

  showError = () => {
    return <LoadingDataError/>;
  };

  render() {
    const t = this.props.t;

    // If we came here because this page is the HOME page, then assume current company is the user's company
    // and rewrite URL. First check that we are logged in because getDefaultRoute will complain
    if (!JobManagementRoutes.getCompanyIdFromRoute(this.props.match) && this.props.authUser.isAuthenticated)
      return <Redirect to={JobManagementRoutes.getDefaultRoute(this.props.authUser.profile._id)}/>;

    return (
      <PrivatePage>
        <QueryCurrentJobsForCompany
          _id={JobManagementRoutes.getCompanyIdFromRoute(this.props.match)}
          onLoading={this.showLoading}
          onError={this.showError}
          onLoaded={(jobs) => {
            const openingIndex = TypeUtils.ensureArray(jobs.currentJobOpenings).findIndex((job) => job._id === this.state.selectedOpening);
            const opening = openingIndex === -1 ? null : jobs.currentJobOpenings[openingIndex];

            return (
              <Row>
                <Col xs="4">
                  <JobOpeningsList
                    openings={[...TypeUtils.ensureArray(jobs.currentJobOpenings)].reverse()}
                    selectOpening={this.selectOpening}
                    selectedOpening={this.state.selectedOpening}
                    companyName={jobs.name}
                  />
                </Col>
                <Col>
                  {this.props.addOpening ? <AddJobTab/> : <WithTranslationJobTabs opening={opening}/>}
                </Col>
              </Row>
            );
          }}
        />
      </PrivatePage>
    );
  }
}

class JobTabs extends React.Component {

  render() {

    const previewTabTitle = (
      <Trans i18nKey="jobmanagement:job_management_preview_tab">
        <PreviewIcon className="tab-icon"/>Preview
      </Trans>
    );

    const candidateTabTitle = (
      <Trans i18nKey="jobmanagement:job_management_candidates_tab">
        <CandidateIcon className="tab-icon"/>Candidates
      </Trans>
    );

    const starsTabTitle = (
      <Trans i18nKey="jobmanagement:job_management_stars_tab">
        <CandidateIcon className="tab-icon"/>Stars
      </Trans>
    );

    const {opening} = this.props;
    const allTabsDisabled = !opening;

    return (
      <div className="JobTabs">
        <Tabs defaultActiveKey="preview" id="job-management-tabs">
          <Tab eventKey="preview" title={previewTabTitle} mountOnEnter
               disabled={allTabsDisabled}>
            <PreviewTab opening={opening}/>
          </Tab>
          <Tab eventKey="feed" title={candidateTabTitle} mountOnEnter
               disabled={allTabsDisabled}>
            <FeedTab opening={opening} updateOpening={this.updateOpening}/>
          </Tab>
          {/*
                    <Tab eventKey="stars" title={starsTabTitle} mountOnEnter
                         disabled={allTabsDisabled}>
                      <StarsTab opening={opening}/>
                    </Tab>
                    */}
        </Tabs>

      </div>
    );
  }
}

const WithTranslationJobTabs = withTranslation()(JobTabs);

export default withTranslation()(withRouter(withAuthUser(JobManagementPage)));
