import React from "react";
import { Link, withRouter } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import "./SignUpPage.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Page from "../page/Page";
//import Image from "react-bootstrap/Image";
//import logo_portal from "../../img/bsl-cropped.png";
import SignUpForm from "./SignUpForm";
import {withTranslation} from "react-i18next";

class SignUpPage extends React.Component {
  render() {
    return (
      <Page>
        <div className="SignUpPage spaced-children">
          {/*
          <Row>
            <Col>{empty row for even spacing}</Col>
          </Row>
          <Row>
            <Col>
              <Image src={logo_portal} className="portal_logo" />
            </Col>
          </Row>
          */}
          <Row className="big-space-before">
            <Col xs="12" sm="6">
              <WithRouterSignUpBlock />
            </Col>
          </Row>
        </div>
      </Page>
    );
  }
}

class SignUpBlock extends React.Component {
  render() {
    const t = this.props.t;

    // If we tried to access a private page without being authenticated, signing up will redirect
    // to referrer. Keep the referrer if user has an account and does not need to sign up, just sign in
    const signInLocationWithReferrer = {
      pathname: ROUTES.SIGN_IN,
      state: this.props.location.state
    };

    return (
      <Card className="SignUpBlock">
        <Card.Body className="spaced-children">
          <Row>
            <Col className="title-zone">
              <h4>{t("session:sign_up_title")}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <SignUpForm />
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                {t("session:sign_up_existing_account_msg")}{" "}
                <Link to={signInLocationWithReferrer}>{t("session:sign_up_sign_in_link")}</Link>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

const WithRouterSignUpBlock = withTranslation()(withRouter(SignUpBlock));

export default SignUpPage;
