import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {withTranslation} from "react-i18next";
import "./CandidateSearchPane.scss";
import JobFairFilter from "./JobFairFilter";
import {Link} from "react-router-dom";
import withRoundButton from "../materialicon/withRoundButton";
import AddIcon from "@material-ui/icons/Add";
import * as ROUTES from "../../constants/routes"

class CandidateSearchPane extends React.Component {

  updateGeneral = general => {
    this.props.updateSearchFilters({ general: general });
  };

  updateJobFairId = jobFairId => {
    this.props.updateSearchFilters({ jobFairId: jobFairId });
  };

  render() {
    const t = this.props.t;

    return (
      <div className="CandidateSearchPane">
        <Card className="SearchPaneTile">
          <Card.Header>
            <Row>
              <Col>
                {t("jobmanagement:candidate_add_label")}
              </Col>
              <Col xs="auto">
                <Link to={ROUTES.CANDIDATE_ADD}>
                  <WithRoundButtonAddIcon className="add-button" title={t("jobmanagement:candidate_add_label")}/>
                </Link>
              </Col>
            </Row>
          </Card.Header>
        </Card>
        <Card className="SearchPaneTile">
          <Card.Header>{t("jobmanagement:candidate_filter_title")}</Card.Header>
          <Card.Body className="spaced-children">
            {/*
            <Row className="filter-row">
              <Col>
                <GeneralFilter
                  updateSelectedValues={this.updateGeneral}
                  selectedValues={this.props.searchFilters.general}
                />
              </Col>
            </Row>
            */}
            <Row className="filter-row">
              <Col>
                <JobFairFilter
                  searchFilters={this.props.searchFilters}
                  updateSelectedValues={this.updateJobFairId}
                  selectedValues={this.props.searchFilters.jobFairId}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const WithRoundButtonAddIcon = withRoundButton(AddIcon);

export default withTranslation()(CandidateSearchPane);
