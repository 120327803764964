class StringUtils {

  /**
   * Returns the string passed as argument, or the empty string "" if argument is null or undefined
   * @param string String to return, if not null nor undefined
   * @returns {*} String passed as argument if not null nor undefined, otherwise the empty string ""
   */
  static nullToEmpty = (string) => {
    return (string == null ? "" : string);
  };

  /**
   * Returns the number passed as argument, or 0 if argument is null or undefined
   * @param number Number to return, if not null nor undefined
   * @returns {*} Number passed as argument if not null nor undefined, otherwise 0
   */
  static nullToZero = (number) => {
    return (number == null ? 0 : number);
  };

  /**
   * Returns true if the string is either null, undefined or empty ("")
   * @param string String
   * @returns {boolean} True if string is null, undefined or empty ("")
   */
  static isNullOrEmpty = (string) => {
    return StringUtils.nullToEmpty(string) === "";
  };

  /**
   * Returns true if the string is either "1", "true" or "TRUE", otherwise returns false.
   * @param string String to analyze (such as a configuration parameter from file)
   * @return {boolean} True if string is either "1", "true" or "TRUE"
   */
  static stringIsTrue = (string) => {
    return (string === "1" || string === "true" || string === "TRUE");
  };

  /**
   * Normalize strings so that close duplicates can be removed.
   * Trim, then convert to lowercase.
   * @param string
   * @returns {string}
   */
  static normalizeString = (string) => {

    // For now, do not remove accents, because fields indexed in GraphQL are case-insensitive (collation strength = 2)
    // but they are still diacritics-sensitive. So it's ok to display to user the accented variation and unaccented
    // variation of a string because they will produce 2 different search results.
    // More info on using .normalize to remove accents:
    // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
    // return string.trim().toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return string.trim().toLocaleLowerCase();
  };

  /**
   * Escapes all special characters in a string so that it can be used in a RegExp.
   * @param string String to escape
   * @return {*} Escaped string
   */
  static escapeForRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
}

export default StringUtils;
